.status-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dee2e6;
  }
  
  .status-bar button {
    width: 99vw;
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #ddd;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  .status-bar button:hover {
    background-color: #ddd;
  }
  
  .status-bar button.active {
    color: white;
    background-color: #4caf50;
    border-radius: 4px;

  }
  
  
  .component-container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  