.wr_application {
  padding: 50px 0;
}
.applicationForm {
  width: 95%;
  max-width: 768px;

  padding: 10px;
  border: 1px solid black;
}
.careerbackb {
  border-radius: 4px;
  background-color: white;
  border: 2px black solid;
  top: 100px;
  margin-left: 15px;
  z-index: 10;
}

.careerbackb:hover {
  background-color: black;
  color: white;
}

.careerPlanArea {
  height: 150px;
}
