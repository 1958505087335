.individual-agency {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Quicksand", sans-serif;
}


.agency-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agency-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-right: 20px;
  cursor: pointer;
}

.editAgencyImg img{
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.editAgencyImg{
  right: 20px;
  top:8px;
  background-color: #e0e0e0;
  
}

.agency-info {
  flex-grow: 1;
}
.aboutAgency{
  resize: none;
}
.agency-name {
  font-size: 2rem;
  margin: 0;

}

.agency-name-edit{
  border-top:0;
  border-right:0;
  border-left:0;
  border-bottom: 1px solid black;
  border-radius: 0;
}
.agency-name-edit:focus,.aboutAgency:focus{
  outline: none;
  
}
.agency-rating {
  font-size: 1.2rem;
  color: #777;
}
.custom-file-upload {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.custom-file-button {
  background-color: #4CAF50; /* Green */
  color: white;
  border: 2px solid #4CAF50;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-file-button:hover {
  background-color: white;
  color: #4CAF50;
}

.detail-row {
  display: flex;
  align-items: center;
}

.detail-label {
  min-width: 150px; /* Adjust width as needed */
  text-align: left;
  padding-right: 5px;
}

.detail-colon {
  margin: 0 5px;
}

.detail-value {
  flex: 1;
  word-wrap: break-word;
}

.detail-row .detail-label,
.detail-row .detail-colon,
.detail-row .detail-value {
  display: inline-block;
}






.agency-details, .agency-reviews {
  margin-top: 20px;
}

.agencyOtherDetails {
  margin-bottom: 20px;
}

.agencyOtherDetails h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}


.custom-table .label {
  width: 15%; /* Adjust the width as needed */
  white-space: nowrap;
  padding-right: 5px; /* Add some space between label and colon */
}

.custom-table .colon {
  width: 5%; /* Adjust the width as needed */
  text-align: center;
}

.counStatCitSelect{
  padding: 6px;
}
.addCity{
  width:150px !important; 
}
.addcityBtn{
  background-color:#4CAF50;
  padding: 5px;
  height: 100%;
}
.addCity:focus{
  outline: none;
}
.counStatCitSelect:focus{
  outline: none;
}



input, textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.indAgencyCancelBtn,.indAgencySaveBtn{
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.indAgencyBackBtn ,.indAgencyAddBtn{
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.indAgencyBackBtn:hover::after{
  content: "Back";
  position: absolute;
  bottom: -40px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}
.editAgencyImg:hover::after{
  content: "Edit Image";
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}



.individualRatingColor{
  background-color: #BAE475;
  width: 80px;
}
.establishedIcon{
  width: 20px;
  height: 20px;
}
.deleteIcons{
  width: 25px;
  height: 25px;
  margin: 18px 5px;
  cursor: pointer;
}
.websiteLinks,.socialLinks{
  width:100%;
}
.websiteLinks:focus,.socialLinks:focus{
  outline: none;
}
.agencyImageEdit{
  display: none;
  width: 9.5rem;
}
