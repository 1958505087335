.cropManagPics{
    width: 300px;
    height: 200px;
}
.cropManageBreif{
    width: 50%;
}
.pestControl{
    width: 90%;
    height: 550px;
}
.cropManagPestControl{
    width: 25%;
}
.cropManagePractices{
    width: 35%;
}
.cropManageHeadings{
    font-size: 20px;
}
.transperentCircleLayerRight{
    width: 700px;
    height: 700px;
    background-color: rgba(151,239,121,27%);
    right:-450px;
    /* margin-top: -200px; */
    z-index:-1;
}
.leaves{
    top:100px;
    right: -150px;
}
.cropMangeKnowMore{
    width: 100%;
    border: none;
    background-color: #D5F187;
}.cropMangPestControlContentBtn{
    
    width: 90%;
    font-family: "Jost", sans-serif;
}
.cropMangementPara{
    width: 75%;
    font-family: "Jost", sans-serif;
}
.practiceContent p{
    
    font-family: "Jost", sans-serif;
}

@media screen and (max-width:767px) and ( min-width:0px) {
    .transperentCircleLayerRight{
        display: none;
    }
    .leaves{
        display: none;
    }
    .cropManageBreif{
        width: 100%;
    }
    .cropMangementPara{
        width: 100%;
    }
    .cropManagPestControl{
        width: 90%;
    }
    .cropManagePractices{
        width: 90%;
    }
    .cropManagePractice{
        flex-direction: column;
    }
    .cropManagePracticeLayer2{
        flex-direction: column-reverse;  
    }
        .pestControl{
            width: 70%;
            height: 350px;
        }
    .cropMangPestControlContentBtn{
        width: 70%;
    }
    .cropManagPics{
        width: 70%;
        height: auto;
    }
    .cropManagementWrapper{
        flex-direction: column;
    }
    .cropMangeKnowMore{
        width: 70%;
     
    }
    .practiceContent p,.practiceContent h2{
        width: 70%;
    }
    .practiceContent{
        align-items: center;
    }
    .cropManageHeadings{
        font-size: 18px;
    }
}

@media screen and (max-width:1023px) and (min-width:768px) {
    .transperentCircleLayerRight, .leaves{
        display: none;
    }
    .cropManagPestControl{
        width: 35%;
    }
    .cropManagePractices{
        width: 60%;
    }
    .cropMangPestControlContentBtn{
        width: 90%;
    }
    .cropManagPics{
        width: 100%;
        height: auto;
    }
    .cropMangeKnowMore,.practiceContent p,.practiceContent h2, .pestControl, .cropManageBreif, .cropMangementPara{
        width: 100%;
    }
}
@media screen and (max-width:1440px) and (min-width:1024px){
    .transperentCircleLayerRight{
        width: 600px;
        height: 600px;
        background-color: rgba(151,239,121,27%);
        right:-450px;
        /* margin-top: -200px; */
        z-index:-1;
    }
    .leaves{
        right: -350px;
    }
    .cropManagPestControl{
        width: 25%;
    }
    .cropManagePractices{
        width: 45%;
    }
    .cropMangPestControlContentBtn{
        width: 90%;
    }
    .cropManagPics{
        width: 100%;
        height: auto;
    }
    .cropMangeKnowMore,.practiceContent p,.practiceContent h2, .pestControl, .cropManageBreif, .cropMangementPara{
        width: 100%;
    }
}
