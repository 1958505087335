/* Table Styles */
.tableComponentWrapper {
  overflow-x: auto;
  width: 80%;
}

.tableComponent {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.tableComponent th,
td {
  padding: 1rem;
  text-align: left;
}

.tableComponent th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #495057;
  border-bottom: 2px solid #dee2e6;
}

.tableComponent td {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6;
}

.tableComponent tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.tableComponent tr:hover td {
  background-color: #e9ecef;
}

.searchStatus {
  right: 5px;
  top: 8px;
  font-size: 12px;
}

.version {
  background-color: rgb(70, 70, 70);
}

/* Enhanced Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  padding: 1rem;
  list-style-type: none;
  margin: 1rem 0;
}

.pagination li {
  margin: 0 0.25rem;
}

.pagination li a {
  border: 1px solid #dee2e6;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  text-decoration: none;
  color: #007bff;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.pagination li a:hover {
  background-color: #007bff;
  color: #fff;
}

.pagination li.active a {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

/* StatusBar Styles */
/* StatusBar Styles */
.status-barBlogs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 98vw;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-bottom: 20px;
  font-family: "Jost", sans-serif;
}

.status-optionBlogs {
  cursor: pointer;
  padding: 15px 30px;
  text-align: center;
  flex-grow: 1;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.status-optionBlogs.bg-success:hover {
  background-color: #28a745;
  color: white;
}

.status-optionBlogs.bg-danger:hover {
  background-color: #dc3545;
  color: white;
}

.status-optionBlogs.bg-warning:hover {
  background-color: #ffc107;
  color: black;
}

.status-optionBlogs.bg-primary:hover {
  background-color: #007bff;
  color: white;
}

.viewBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.viewBtn:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.viewBtn:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* Search Bar Styles */
.searchBarRole {
  margin-bottom: 10px;
  padding: 10px 45px 10px 15px ;
  width: 500px !important;
  max-width: 600px;
  border: 1px solid #ced4da;
  /* border-radius: ; */
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.searchBarRole:focus {
  /* border-color: #007bff;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25); */
  outline: none;
}
.searchIcon{
  padding: 11px;
  background-color: #4caf50;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.searchDelete{
  top:9px;
  right:43px
}
.blogNameTooltip:hover::after {
  content: "dfg";
  position: absolute;
  bottom: -40px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}
.viewIcon,.rowClick{
  cursor: pointer;
}


/* Responsive Design */
@media (max-width: 768px) {
  .status-optionBlogs {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .tableComponent th,
  td {
    font-size: 0.6rem;
  }
  .searchBarRole{
    width: 400px !important;
    padding: 5px;
  }
  .searchIcon{
    padding: 6px;
  }
  .searchDelete{
    top:5px;
    right: 30px;
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .searchStatus {
    right: 5px;
    top: 8px;
    font-size: 8px;
  }

  .tableComponentWrapper {
    width: 100%;  

  }

  .status-optionBlogs {
    font-size: 0.575rem;
    padding: 10px 10px;
  }

  .tableComponent th,
  td {
    font-size: 0.575rem;
  }

  .pagination li a {
    padding: 0.375rem 0.5rem;
  }

  .viewBtn {
    padding: 0.3rem 0.3rem;
    font-size: 0.5rem;
  }
  .searchBarRole{
    width: 280px !important;
    padding: 5px;
  }
  .searchDelete{
    top:5px;
    right: 30px;
  }
  .searchIcon{
    padding: 6px;
  }
}