.careerback {
  border-radius: 4px;
  color: rgb(0, 0, 0);
  background-color: white;
  border: 2px black solid;
  left: -10px;
  top: 100px;
  margin-left: 30px;
}
.careerback:hover {
  color: white;
  background-color: black;
}

.editPost {
  padding: 4px;
  border-radius: 10px;
  border: 1px black solid;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-right: 30px;
}
.editPost:hover::after {
  content: "Edit Post";
  position: absolute;
  bottom: -40px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease-in-out;
}

.editPost:hover::after {
  opacity: 1; /* Show on hover */
}

.jobDescription {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 5px;
  max-width: 700px;
  min-height: 550px;
}
.jobHeading {
  font-size: 25px;
  margin-top: 50px !important;
  font-weight: 700;
  color: orangered;
}
.jobDescription a {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
@media screen and (min-width: 768px) {
  .jobHeading {
    font-size: 40px;
  }
  .jobDescription {
    padding-left: 50px;
  }
}
@media screen and (min-width: 992px) {
  .editPost {
    width: 40px;
    height: 40px;
  }
}
