@import url("https://fonts.googleapis.com/css2?family=Carattere&display=swap");
.mainBlogPageSection {
  background-color: rgba(255, 255, 255, 0.788);
}

.backToBlog {
  border-radius: 4px;
  color: rgb(0, 0, 0);
  background-color: white;
  border: 2px black solid;
  left: 0;
  z-index: 2;
  margin-left: 25px;
  top:110px;
}
.backToBlog:hover {
  color: white;
  background-color: black;
}
.blogDetailPage {
  padding-top: 40px !important;
  line-break:auto;
}
.editbPost {
  padding: 4px;
  background-color: black;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-right: 30px;
}
.rmPin {
  background-color: white;
  color: black;
}
.edit2:hover::after {
  content: "Edit Post";
}
.edit2:hover::after,
.pinned:hover::after,
.rmPin:hover::after {
  position: absolute;
  bottom: -40px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}

.edit2:hover::after,
.pinned:hover::after,
.rmPin:hover::after {
  opacity: 1; /* Show on hover */
}
.pinned:hover,
.del:hover {
  background-color: red;
  color: black;
  border: 1px solid red;
}
.pinned:hover::after {
  content: "UnPin";
}

.rmPin:hover::after {
  content: "Pin";
}

.blogDetailPage {
  padding-top: 50px;
  min-height: 700px;
}
.Blog_heading {
  font-size: 40px;
  /* line-break: auto; */
}
.Blog_Image {
  width: 90%;
  height: auto;
  margin: 10px auto;
}

.BlogForm {
  background-color: rgba(75, 70, 95, 0.658);
  padding: 20px;
  border-radius: 20px;
  max-width: 400px;
}
.publishedUser{
  float: right;
}


@media screen and (min-width: 768px) {
  .editbPost {
    width: 40px;
    height: 40px;
  }
}
@media screen and (min-width: 992px) {
  .blogDetailPage {
    margin-left: 1rem;
    width: 500px;
  }
  .BlogForm {
    width: 500px;
    margin-top: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .blogDetailPage {
    width: 700px;
  }
  .registration {
    margin-left: 6rem;
  }
}
