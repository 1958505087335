.transperentCircleLayer{
    width: 750px;
    height: 800px;
    background-color: rgba(151,239,121,27%);
    left:-400px;
    z-index:-1;
}
.soilContent{
    width: 30%;
}
.soilHealthPractices{
    width: 42%;
    
}

.soilHealthImg{
    width: 250px;
    height: 250px;
}
.potPlant{
    left:-170px;
    top:150px;
}

.unlockColor{
    color: #FF5C00;
}
.soilContentPara{
    width:70%

}
.soilHealthHeading{
    font-size: 50px;
        font-family:  "Julius Sans One", sans-serif;
}
.soilExplore{
    padding: 15px;
    border-radius: 30px;
    border: none;
    text-decoration: none;
    -webkit-box-shadow: 0px -1px 5px 0px rgba(182, 182, 182, 0.75);
-moz-box-shadow: 0px -1px 5px 0px rgba(182, 182, 182, 0.75);
box-shadow: 0px -1px 5px 1px rgba(182, 182, 182, 0.75);
}
.soilPrecautionName{
    justify-content: center;
    font-size: 25px;
}
.soilHealthLinks{
    text-decoration: none;
}
@media screen and (max-width:992px) and (min-width:0px) {
    .transperentCircleLayer{
        display: none;
    }
    .potPlant{
        display: none;
    }
    .soilHealthImg{
        width: 200px;
        height: 200px;
    }
    .soilHealthPractices{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .soilContent{
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .soilHealthHeading{
        font-size: 30px;
        text-align: center;
    }
    .soilHealthWrapper{
        flex-direction: column;
    }
    .soilPrecautionName{
        font-size: 18px;
        justify-content: center;
    }
}
@media screen and (max-width:1440px) and (min-width:993px) {
    .potPlant{
        width: 300px;
        height: 300px;
        left:-150px;
        top:150px;
    }
    .transperentCircleLayer{
        width: 600px;
        height: 600px;
        background-color: rgba(151,239,121,27%);
        left:-400px;
        z-index:-1;
    }
    .soilHealthImg{
        width: 200px;
        height: 200px;
    }
    .soilHealthPractices{
        width: 50%;
    }
    .soilContent{
        width: 35%;
    }
    .soilHealthHeading{
        font-size: 30px;
    }
    .soilHealthWrapper{
        flex-direction: row;
    }
    .soilPrecautionName{
        font-size: 18px;
        justify-content: center;
    }
   
}