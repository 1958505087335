.custom-toggler img {
  width: 30px;
  height: 30px;
}

.navbar {
  z-index: 4;
  width: 100%;
  height: 90px;
  transition: all ease 0.5s;
}

.navbar-toggler {
  box-shadow: none !important;
}


.navLinks .active {
  background-color: #C4FF78;
  height: 35px;
}

.navLinks a {
  font-size: 16px;
  font-weight: 500;
  margin: 20px;
  border-radius: 20px;
  color: black;
  text-align: center;
  text-decoration: none;
  padding: 5px 10px;

}

.navLinks .active {
  background-color: #C4FF78;
  border-radius: 20px;
  animation: all ease 1s;
}

.sticky-nav {
  background-color: rgb(255, 255, 100) !important;
}

.logo {
  margin-left: 30px;
  width: 55px;
  cursor: pointer;
}

.profileImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

@media screen and (max-width:425px) and (min-width:320px) {
  .logo {
    width: 50px;
  }
}

/* @media screen and (min-width: 992px) {
  .navLinks {
    background-color: transparent;
  }
 
} */
@media screen and (max-width:991px) and (min-width:0px) {
  .mb-navbarC {
    background-color: #C4FF78;
  }
}