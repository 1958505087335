.maintanenceImg img{
    width: 50%;
    
}
.underMaintenanceWrapper{
    margin-top: 10%;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-family: "Questrial", sans-serif;
}

@media screen and (max-width:768px) {
 .maintenanceText h1{
    font-size: 22px;
 }
 .maintenanceText h2{
    font-size: 20px;
 }
 .maintenanceText p{
    font-size: 16px;
 }
}